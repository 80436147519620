import {memo, useEffect, useRef, useState} from 'react';
import ReactPlayer from 'react-player';

import {AndroidSDKEvent} from 'shared/utils/eventsSdk';
import {logger as baseLogger} from 'shared/utils/logger';

import type {OnProgressProps} from 'react-player/base';
import type {TrackProps} from 'react-player/file';

const sdkEvent = new AndroidSDKEvent();

const logger = baseLogger.child({tag: '[VideoPlayer Component]'});

type VideoPlayerProps = {
  url: string;
  muted: boolean;
  playing: boolean;
  tracks?: TrackProps[];
  className?: string;
  onStart?: () => void;
  onReady?: (player: ReactPlayer) => void;
  onPlay?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
  onBuffer?: () => void;
  onBufferEnd?: () => void;
  onDuration?: (duration: number) => void;
  onProgress?: (state: OnProgressProps) => void;
  onError?: (error: unknown) => void;
  volume?: number;
};

const VideoPlayer = memo((props: VideoPlayerProps) => {
  const {
    url,
    muted,
    playing,
    tracks,
    onStart,
    onReady,
    onPlay,
    onPause,
    onEnded,
    onBuffer,
    onBufferEnd,
    onDuration,
    onProgress,
    onError,
    volume,
    className,
  } = props;
  const [showPlayer, setShowPlayer] = useState(true);
  const videoPlayerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    if (videoPlayerRef.current) {
      // @ts-expect-error Parameter 'getActivePlayer' implicitly has an 'ReactPlayer'
      (videoPlayerRef.current as ReactPlayer).getActivePlayer().lazyPlayer['forceLoad'] = true;
    }
  }, [url]);

  const destroyPlayer = () => {
    logger.debug('Got onEnded event.');
    if (videoPlayerRef.current) {
      const internalPlayer = videoPlayerRef.current.getInternalPlayer();
      if (internalPlayer && typeof internalPlayer.pause === 'function') {
        internalPlayer.pause();

        while (internalPlayer.firstChild) {
          internalPlayer.removeChild(internalPlayer.firstChild);
        }
        internalPlayer.removeAttribute('src');

        internalPlayer.load();
      }
    }
    setShowPlayer(false);
  };

  if (!showPlayer) {
    return null;
  }

  return (
    <ReactPlayer
      width="100%"
      height="100%"
      volume={volume || 0.5}
      url={url}
      ref={videoPlayerRef}
      controls={__DEV__}
      muted={muted}
      playing={playing}
      className={className}
      onStart={() => {
        logger.debug(`Got onStart event: [${url}]`);
        onStart?.();
      }}
      onReady={(player) => {
        logger.debug(`Got onReady event: [${url}]`);
        onReady?.(player);
      }}
      onPlay={() => {
        logger.debug(`Got onPlay event: [${url}]`);
        onPlay?.();
      }}
      onPause={() => {
        logger.debug(`Got onPause event: [${url}]`);
        onPause?.();
      }}
      onEnded={() => {
        logger.debug(`Got onEnded event: [${url}]`);
        destroyPlayer();
        onEnded?.();
      }}
      onBuffer={() => {
        logger.debug(`Got onBuffer event: [${url}]`);
        onBuffer?.();
      }}
      onBufferEnd={() => {
        logger.debug(`Got onBufferEnd event: [${url}]`);
        onBufferEnd?.();
      }}
      onDuration={(duration) => {
        logger.debug(`Got onDuration event: [${url}]`, {duration});
        onDuration?.(duration);
      }}
      onProgress={(progress) => {
        const playedSeconds = Math.trunc(progress.playedSeconds);
        if (playedSeconds % 2 === 0) {
          sdkEvent.sendHeartbeatWithInternetSpeedMonitoring();
        }
        onProgress?.(progress);
      }}
      onError={(error) => {
        logger.warn(`Got onError event: [${url}]`, {error});
        onError?.(error);
      }}
      config={{
        file: {
          attributes: {
            poster: '/load-video.png',
          },
          tracks: tracks || [],
        },
      }}
    />
  );
});

VideoPlayer.displayName = 'VideoPlayer';
export default VideoPlayer;
