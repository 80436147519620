
import VideoJsPlayer from 'shared/components/VideoPlayer/VideoJsPlayer';
import VideoPlayer from 'shared/components/VideoPlayer/VideoPlayer';
import LaunchDarklyService from 'shared/utils/launch-darkly-service';

/**
 * Returns the selected video player component based on the feature flag.
 *
 * This hook checks the flag "video-component-video-library" from LaunchDarklyService and
 * returns either the ReactPlayer-based VideoPlayer or the Video.js-based VideoJsPlayer.
 *
 * @return {React.ComponentType} The selected video player component.
 */
export default function useSelectedPlayer() {
  const videoComponentLibrary = LaunchDarklyService.getFlag('video-component-video-library');

  return videoComponentLibrary === 'react-player' ? VideoPlayer : VideoJsPlayer;
}
