import {useEffect} from 'react';

import {v4 as uuid} from 'uuid';

import LaunchDarklyService from 'shared/utils/launch-darkly-service';
import {logger as baseLogger} from 'shared/utils/logger';

const logger = baseLogger.child({tag: '[Video Observer]'});

const uid = uuid();

/**
 * 🚨TEMPORARY DIAGNOSTIC METRIC.
 * Custom React Hook that observes the DOM for newly added or removed `<video>` elements.
 *
 * 📌 **Functionality:**
 * - Monitors the DOM for changes using `MutationObserver`.
 * - Logs the number of active `<video>` elements every time a change occurs.
 * - Generates a unique observer ID for each instance.
 * - Displays an error if more than one `<video>` tag is detected, indicating a possible memory leak.
 *
 * ⚠️ **Performance Considerations:**
 * - `MutationObserver` can be **CPU-intensive** if there are frequent DOM changes.
 * - Observing the entire document body (`subtree: true`) may slow down rendering for large applications.
 * - Overhead increases as more components register observers, leading to excessive logging.
 * - Frequent logs may **flood the console**, making debugging harder.
 * - Alternative approaches (e.g., checking only a specific container) might be more efficient.
 */
const useVideoTagObserver = () => {
  const systemLoggingLevel = LaunchDarklyService.getFlag('system-logging-level');

  useEffect(() => {
    if (systemLoggingLevel !== 'debug') return;

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const videoTags = document.querySelectorAll('video');
          const instanceCount = videoTags.length;

          logger.debug(`Unique ID ${uid} | Detected ${instanceCount} <video> elements`);

          if (instanceCount > 1) {
            logger.error(`Unique ID ${uid} | Detected ${instanceCount} <video> elements 🚨`);
          }
        }
      });
    });

    observer.observe(document.body, {childList: true, subtree: true});

    return () => observer.disconnect();
  }, [systemLoggingLevel]);
};

export default useVideoTagObserver;
