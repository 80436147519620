import {meteEnvConfig} from 'config';
import {AdTypes} from 'features/adoppler';

import type {AdStrategyName, AdUnitType} from 'types';

import {logger as baseLogger} from './logger';

const logger = baseLogger.child({tag: '[Url Utils]'});

/**
 * capitalize 1st letter in string
 * @param {string} value
 * @return {string}
 */
export function capitalizeFirstLetter(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 * Remove leading/end spaces from the string
 * @param {string} ad
 * @return {string}
 */
export function normalizeAd(ad: string): string {
  return capitalizeFirstLetter(ad.toLowerCase().trim());
}

/**
 * Verify if value has 0/1
 * @param {string} value we need to verify
 * @return {boolean}
 */
export function isBit(value: string): boolean {
  return !isNaN(parseInt(value)) && Boolean(~[0, 1].indexOf(parseInt(value)));
}

/**
 * Handles custom parameters from the URL query string and updates the configuration accordingly.
 */
export function handleCustomParameters(): void {
  const adsTypes = getQueryParam('ads_types');
  const parsedAdsTypes: string[] = adsTypes ? adsTypes.split(',') : [];
  const normalizedTypes: (string | number)[] = parsedAdsTypes
    .map((ad) => normalizeAd(ad))
    .map((ad) => (isNaN(parseInt(ad)) ? ad : parseInt(ad)));
  const validTypes = normalizedTypes.filter((ad) => ad in AdTypes);

  if (parsedAdsTypes.length !== validTypes.length) {
    logger.error(
      'Error while parsing query parameters - passed invalid ads_types parameter',
      window.location.href,
    );
  }

  meteEnvConfig.ads.types = validTypes as (AdTypes)[];

  const strategyName = getQueryParam('strategy') as AdStrategyName;
  meteEnvConfig.ads.strategy = strategyName ? strategyName : meteEnvConfig.ads.strategy;

  const gptAdUnit = getQueryParam('ad-unit');
  meteEnvConfig.ads.adUnit = gptAdUnit ? (gptAdUnit.toUpperCase() as AdUnitType) : meteEnvConfig.ads.adUnit;

  const vMuted = getQueryParam('mute');
  meteEnvConfig.ads.video.mute = vMuted && (isBit(vMuted))? parseInt(vMuted) : meteEnvConfig.ads.video.mute;

  const vAutoplay = getQueryParam('autoplay');
  meteEnvConfig.ads.video.autoplay = vAutoplay && (isBit(vAutoplay))
  ? parseInt(vAutoplay)
  : meteEnvConfig.ads.video.autoplay;

  const clickDebugger = getQueryParam('click-debugger');
  meteEnvConfig.ads.debug.tellySdkClickEvents = clickDebugger === 'true' || false;
  logger.debug('Parsed URL query params', {adsTypes, strategyName, gptAdUnit, vMuted, vAutoplay});
}

/**
   * Checks if a given URL is a valid image URL.
   *
   * @param {string} url - The URL to be checked for validity as an image URL.
   * @return {boolean} - Returns true if the URL is a valid image URL, otherwise false.
   *
   * @example
   * // Check if a URL is a valid image URL:
   * const url = 'https://example.com/image.jpg';
   * const isValidImage = isValidImageUrl(url); // true
   */
export function isValidImageUrl(url: string): boolean {
  const urlPattern = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
  const extensionPattern = /\.(jpeg|jpg|gif|png)$/i;

  return urlPattern.test(url) && extensionPattern.test(url);
}

/**
   * Retrieves the value of a query parameter from the current URL's query string.
   *
   * @param {string} name - The name of the query parameter to retrieve.
   * @return {string | null} - The value of the specified query parameter, or null if not found.
   *
   * @example
   * // Assuming the URL is: https://example.com/page?param1=value1&param2=value2
   * const paramValue = getQueryParam('param1'); // 'value1'
   * const nonExistentParam = getQueryParam('param3'); // null
   */
export function getQueryParam(name: ParamField): string | null {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString.toLowerCase());

  return urlParams.get(name.toLowerCase());
}

type ParamField = 'ads_types' | 'strategy' | 'gpt-sizes' | 'ad-unit' | 'mute' | 'autoplay' | 'click-debugger';
