import Joi from 'joi';
import {initialize} from 'launchdarkly-react-client-sdk';

import {meteEnvConfig} from 'config';
import {logger as baseLogger} from 'shared/utils/logger';

import type {DeviceInfo, KeysOfUnion} from 'types';

import {flagDetailChangedInspector} from './helpers';
import validate from './validator';

import type {FlagSet} from './types';
import type {LDClient, LDMultiKindContext} from 'launchdarkly-react-client-sdk';
import type {Logger} from 'pino';

const CACHE_DURATION_MS = 10 * 60 * 1000;

/**
 * Joi schema for validating an object that conforms to the `FlagSet` interface.
 *
 * The schema specifies that the object must include certain required keys,
 * each with a specific type and set of valid values.
 *
 * - Any additional keys not defined in the schema will be allowed.
 * - If the object contains any keys defined in the schema, they will be validated.
 * - If any of these keys are present, they must conform to the valid values.
 *
 * This schema is flexible in terms of required fields but enforces validation on defined fields if present.
 *
 * @type {Joi.ObjectSchema<FlagSet>}
 */
export const schema: Joi.ObjectSchema<FlagSet> = Joi.object<FlagSet>({
  closedCaptionsStatus: Joi.string().valid('enabled', 'disabled', 'forced').required(),
  'video-component-transcoding-status': Joi.string().valid('enabled', 'disabled', 'forced').required(),
  'system-logging-level': Joi.string()
    .valid('trace', 'debug', 'info', 'warn', 'error', 'fatal', 'silent', 'disabled').required(),
  'active-ad-server': Joi.string().valid('elemental', 'ad-proxy'),
  'adam-video-ad-request-strategy': Joi.string().valid('cache', 'request'),
  'video-component-video-library': Joi.string().valid('video.js', 'react-player'),
}).required().unknown(true);

/**
 * Class representing the LaunchDarkly service for managing feature flags.
 * This class implements a singleton pattern to ensure only one instance of the LDClient exists.
 */
class LaunchDarklyService {
  /**
     * The singleton instance of the LDClient.
     * @type {LDClient | null}
     * @private
     */
  private static instance: LDClient | null = null;

  private static _logger: Logger | null = null;

  /**
   * Retrieves the singleton logger instance for the LaunchDarklyService.
   *
   * This getter checks if a logger instance has already been initialized.
   * If not, it creates a new logger child instance with a unique tag, `[Launch Darkly Flags]`,
   * ensuring that logging from this service is appropriately labeled.
   *
   * @return {Logger} - The initialized logger instance for LaunchDarklyService.
   */
  static get logger() {
    if (!this._logger) {
      this._logger = baseLogger.child({tag: '[Launch Darkly Flags]'});
    }
    return this._logger;
  }

  /**
   * Gets the current instance of LDClient.
   *
   * @return {LDClient | null} - The current instance of LDClient or null if not initialized.
   */
  public static get ldClient(): LDClient | null {
    return LaunchDarklyService.instance;
  }

  /**
       * Sets the LDClient instance.
       *
       * @param {LDClient | null} client - The LDClient instance to be set.
       */
  public static set ldClient(client: LDClient | null) {
    LaunchDarklyService.instance = client;
  }

  /**
     * Returns the singleton instance of the LDClient.
     * If the instance does not exist, it initializes a new one with the provided context.
     *
     * @param {DeviceInfo} deviceInfo - The context object to initialize the LDClient.
     * @return {LDClient | null} - The instance of LDClient or null if clientSideID is not available.
     */
  public static async getInstance(deviceInfo: DeviceInfo | null): Promise<LDClient | null> {
    const clientSideID = meteEnvConfig.launchdarklyClientSideID;

    if (!clientSideID) {
      LaunchDarklyService.logger.warn('LaunchDarkly clientSideID is required');
      return null;
    }
    if (!deviceInfo) {
      LaunchDarklyService.logger.warn('Device Properties are required');
      return null;
    }
    const context = this.mapToContextsFrom(deviceInfo);

    if (!LaunchDarklyService.instance) {
      LaunchDarklyService.instance = initialize(clientSideID, context, {
        bootstrap: 'localStorage',
        diagnosticOptOut: true,
        streaming: true,
        flushInterval: CACHE_DURATION_MS,
        inspectors: [flagDetailChangedInspector(LaunchDarklyService.logger)],
        logger: {
          debug: this.logger.debug,
          info: this.logger.debug,
          error: (message) => {
            this.logger.error(message);
          },
          warn: (message) => {
            this.logger.warn(message);
          },
        },
      });
    }

    try {
      await LaunchDarklyService.instance.identify(context).then((data) => {
        this.logger.info('Received flags from LaunchDarkly', data);
      });
    } catch (error) {
      LaunchDarklyService.logger.warn('Failed to identify context', error);
    }

    return LaunchDarklyService.instance;
  }

  /**
   * Retrieves the value of a specific feature flag from LaunchDarkly.
   *
   * This method fetches the value of the provided feature flag key. If the flag
   * is not available or the `LDClient` has not been initialized, a warning will
   * be logged, and `null` will be returned. You can also provide a default value
   * that will be used if the flag is not defined or unavailable.
   * @example const level = LaunchDarklyService.getFlag('system-logging-level', meteEnvConfig.logger.pino.defaultLoggingLevel);
   * return level
   *
   *
   * @param {KeysOfUnion<FlagSet>} key - The key of the feature flag to retrieve.
   * @param {string | undefined} [defaultValue] - The default value to return if the flag is not found or undefined.
   * @return {string | undefined} - The value of the feature flag, or the default value if provided, or `null` if the `LDClient` is not initialized.
 */
  public static getFlag<K extends KeysOfUnion<FlagSet>>(key: K, defaultValue?: FlagSet[K]): FlagSet[K] | null {
    if (!LaunchDarklyService.ldClient) return null;

    return LaunchDarklyService.ldClient.variation(key, defaultValue);
  }

  /**
   * Retrieves and validates feature flags from the LDClient instance.
   *
   * @return {Promise<FlagSet>} - A promise that resolves with the validated set of feature flags.
   * Returns an empty object if there is an error during initialization or validation.
   */
  public static getFlags(): FlagSet {
    if (!LaunchDarklyService.ldClient) {
      LaunchDarklyService.logger.warn('LDClient has not been initialized.');
      return {} as FlagSet;
    }

    try {
      const flags = LaunchDarklyService.ldClient.allFlags() as FlagSet;
      const {error, value} = validate<FlagSet>(flags, schema);
      if (error) {
        LaunchDarklyService.logger.warn('Invalid flags returned:', error.message);
      }

      return value || {} as FlagSet;
    } catch (error) {
      LaunchDarklyService.logger.warn('Failed to retrieve flags from LaunchDarkly', error);
      return {} as FlagSet;
    }
  }

  /**
   * Maps the version information from the device properties into a structured object format
   * and generates a LaunchDarkly multi-kind context object.
   *
   * This function takes the `versions` property from the provided `deviceProperties` object,
   * iterates over its entries, and creates a new object where each key corresponds to a version
   * identifier, and each value is an object containing the version string under the `key` property.
   *
   * @param {DeviceInfo} deviceProperties - The device properties containing version information.
   * @return {LDMultiKindContext} A multi-kind context object structured for LaunchDarkly.
   */
  private static mapToContextsFrom(deviceProperties: DeviceInfo): LDMultiKindContext {
    const versions: Record<string, {key: string}> = {};

    for (const [key, value] of Object.entries(deviceProperties.versions as object)) {
      versions[key] = {
        key: value,
      };
    }

    return {
      kind: 'multi',
      ifaValue: {
        key: deviceProperties?.ad_info.ifa,
        lmt: deviceProperties?.ad_info.lmt,
        ifa_type: deviceProperties?.ad_info.ifa_type,
      },
      gabrielAppVersion: {
        key: __APP_VERSION__,
      },
      adUnit: {
        key: meteEnvConfig.ads.adUnit,
      },
      ...versions,
    };
  }
}

export default LaunchDarklyService;
