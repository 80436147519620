const key = 'mjkl54sghlk';

// Utility to generate a random salt
const generateSalt = (len: number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let salt = '';
  for (let i = 0; i < len; i++) {
    salt += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return salt;
};

// Convert the key into a simple numeric hash
const getKeyHash = (key: string) => {
  return key
    .split('')
    .reduce((hash, char) => hash + char.charCodeAt(0), 0); // Sum of char codes
};

// Helper function to encode data as Base64-like string
const encodeToBase64 = (data: number[]) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'; // Base64 charset
  return data
    .map((value) => characters.charAt(value % 64)) // Map numeric values to Base64 characters
    .join(''); // Combine into a string
};

// Encrypt a string using salt and a string key
export const obfuscate = (plaintext: string) => {
  const salt = generateSalt(3); // Generate a 3-character salt
  const saltedText = salt + plaintext; // Add salt to the plaintext
  const keyHash = getKeyHash(key); // Generate numeric hash from the key

  // XOR each character of the salted text with the key hash
  const encrypted = saltedText
    .split('')
    .map((char) => char.charCodeAt(0) ^ keyHash); // Numeric array after XOR

  const encoded = encodeToBase64(encrypted); // Convert numeric array to Base64-like string
  return `${salt}${encoded}`; // Combine salt and encoded text
};
