import {memo, useEffect, useRef} from 'react';

import videojs from 'video.js';

import {AndroidSDKEvent} from 'shared/utils/eventsSdk';
import {logger as baseLogger} from 'shared/utils/logger';

import type {TrackProps} from 'react-player/file';
import type Player from 'video.js/dist/types/player';

import 'video.js/dist/video-js.css';
import './styles.css';

const sdkEvent = new AndroidSDKEvent();
const logger = baseLogger.child({tag: '[VideoJsPlayer Component]'});

/**
 * Describes the structure for the VideoJsPlayer component props.
 */
type VideoJsPlayerProps = {
  /**
   * The source URL for the video (e.g., "https://example.com/video.mp4").
   */
  url: string;

  /**
   * Indicates whether the video should be muted.
   * @default false
   */
  muted: boolean;

  /**
   * When `true`, attempts to auto-play (or resume) the video.
   * @default false
   */
  playing: boolean;

  /**
   * Optional array of tracks (subtitles, captions, etc.).
   */
  tracks?: TrackProps[];

  /**
   * An optional CSS className for the container element.
   */
  className?: string;

  /**
   * Callback fired the **first** time a video plays.
   * Typically used to track "start" events/metrics.
   */
  onStart?: () => void;

  /**
   * Callback fired when the player is initialized and ready.
   */
  onReady?: () => void;

  /**
   * Callback fired each time the video begins/resumes playback.
   */
  onPlay?: () => void;

  /**
   * Callback fired when the video is paused.
   */
  onPause?: () => void;

  /**
   * Callback fired when the video ends playback.
   */
  onEnded?: () => void;

  /**
   * Callback fired while the video is buffering (the "waiting" event in Video.js).
   */
  onBuffer?: () => void;

  /**
   * Callback fired when the video can play through without buffering.
   */
  onBufferEnd?: () => void;

  /**
   * Callback fired to report the total duration of the video.
   * Typically triggered on HTML5's `durationchange`.
   */
  onDuration?: (duration: number) => void;

  /**
   * Callback fired continuously to report the video's progress/playback time.
   * Receives an object containing `playedSeconds`.
   */
  onProgress?: (state: { playedSeconds: number }) => void;

  /**
   * Callback fired on any video error.
   * Receives the error object (if available).
   */
  onError?: (error: unknown) => void;

  /**
   * The initial volume (0.0 to 1.0). Defaults to 0.5 if not specified.
   */
  volume?: number;
};

/**
 * A React component wrapping the Video.js library for consistent video playback.
 *
 * - It creates/disposes a Video.js player once on mount/unmount.
 * - When `url` changes, it updates the source without disposing the player.
 * - The `playing` prop toggles play/pause state of the underlying video.
 * - Additional callbacks provide event hooks (e.g., onPlay, onEnded, onError).
 */
const VideoJsPlayer = memo((props: VideoJsPlayerProps) => {
  const {
    url,
    muted,
    playing,
    tracks,
    onStart,
    onReady,
    onPlay,
    onPause,
    onEnded,
    onBuffer,
    onBufferEnd,
    onDuration,
    onProgress,
    onError,
    volume,
    className,
  } = props;

  const videoNodeRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<Player | null>(null);

  /**
   * One-time setup: create the Video.js player upon mounting,
   * and dispose it on unmount.
   */
  useEffect(() => {
    if (!videoNodeRef.current) return;

    // Create the Video.js player instance
    playerRef.current = videojs(
      videoNodeRef.current,
      {
        controls: __DEV__,
        autoplay: playing,
        poster: '/load-video.png',
        bigPlayButton: false,
        preload: 'auto',
        loadingSpinner: false,
        errorDisplay: false,
        muted,
        volume,
      },
      function onPlayerReady() {
        logger.debug(`Video.js player ready (initial): [${url}]`);
        onReady?.();
      },
    );

    // Attach event handlers if the player was successfully created
    if (playerRef.current) {
      // Fired whenever playback begins/resumes
      playerRef.current.on('play', () => {
        logger.debug(`Video.js onPlay: [${url}]`);
        onPlay?.();
        onStart?.();
      });

      // Fired on pause
      playerRef.current.on('pause', () => {
        logger.debug(`Video.js onPause: [${url}]`);
        onPause?.();
      });

      // Fired on ended
      playerRef.current.on('ended', () => {
        logger.debug('Video.js onEnded.');
        onEnded?.();
      });

      // Fired continuously during playback
      playerRef.current.on('timeupdate', () => {
        const currentTime = playerRef.current?.currentTime() || 0;
        onProgress?.({playedSeconds: currentTime});

        // Sending a heartbeat every 2 seconds
        if (Math.floor(currentTime) % 2 === 0) {
          sdkEvent.sendHeartbeatWithInternetSpeedMonitoring();
        }
      });

      // Fired on any player error
      playerRef.current.on('error', (error: Error) => {
        logger.warn(`Video.js onError: [${url}]`, {error});
        onError?.(error);
      });

      // Fired while buffering
      playerRef.current.on('waiting', () => {
        onBuffer?.();
      });

      // Fired when we can play without buffering
      playerRef.current.on('canplaythrough', () => {
        onBufferEnd?.();
      });
    }

    // Cleanup on unmount
    return () => {
      if (playerRef.current) {
        logger.debug('Disposing Video.js player instance.');
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * If `url` or `playing` changes, update the existing Video.js player,
   * rather than disposing and re-creating it.
   */
  useEffect(() => {
    const player = playerRef.current;
    if (!player) return;

    logger.debug(`Updating Video.js source: [${url}]`);

    player.src({src: url});
    player.addClass('vjs_video_3-dimensions');
    player.addClass('video-js-override');
    player.poster('');
    player.preload('auto');
    player.ready(function() {
      onReady?.();
    });

    // If "playing" is true, attempt to play the new source
    if (playing) {
      player
        .play()
        ?.catch((error) => {
          logger.warn(`Video.js play error: [${url}]`, {error});
        });
    } else {
      // If not playing, ensure the player is paused
      player.pause();
    }
  }, [url, playing, onReady]);

  /**
   * Handle volume changes and additional player setup whenever
   * muted/volume changes.
   */
  useEffect(() => {
    const player = playerRef.current;
    if (!player) return;

    player.muted(muted);
    player.volume(volume ?? 0.5);
  }, [muted, volume]);

  /**
   * HTMLMediaElement's onDurationChange
   * We also call it if we need to manually check the duration from the player.
   * This is a custom approach (optional).
   */
  const handleDurationChange = () => {
    const duration = playerRef.current?.duration() || 0;
    onDuration?.(duration);
  };

  return (
    <div data-vjs-player className={className}>
      <video
        ref={videoNodeRef}
        onDurationChange={handleDurationChange}
        className="video-js video-js-override vjs-default-skin"
      >
        {tracks?.map((track) => (
          <track key={track.src} {...track} />
        ))}
      </video>
    </div>
  );
});

VideoJsPlayer.displayName = 'VideoJsPlayer';
export default VideoJsPlayer;
