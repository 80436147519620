import type {AdUnitType} from 'types';

import {baseUrl} from './constants.ts';

type Provider = 'elemental'

const getAdProxyUrl = (provider: Provider, adUnit: AdUnitType) => {
  // INFO:
  // For development may used be "return `https://app.freetelly.com/ads/processBid/37176090`;"
  // for fetching ad-pods
  return `${baseUrl}/${provider}/${adUnit}`;
};

const getAdProxyArcUrl = (provider: Provider) => {
  return `${baseUrl}/${provider}/EVE_FULLSCREEN_UNIT_ACR`;
};

export {
  getAdProxyUrl,
  getAdProxyArcUrl,
};
